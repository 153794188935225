<template>
    <!-- 影像服务详情路由 -->
    <div class="photograph_detail_box">
        <div class="container">
            <!-- 面包屑导航 -->
            <div class="breadcrumb_box">
                <bread-crumb :breadcrumb="breadcrumb"></bread-crumb>
            </div>
            <!-- 商品详情 -->
            <photograph-detail-info></photograph-detail-info>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'

export default {
    data() {
        return {
            // 面包屑导航数据
            breadcrumb: [
                {
                    path: "/photograph",
                    path_name: "影像服务",
                },
                {
                    path: "detail",
                    path_name: "详情",
                },
            ],
        }
    },
    components:{
        breadCrumb: ()=> import('@/components/breadCrumb'),
        photographDetailInfo: ()=> import('@/components/photographDetailInfo'),
    },
    methods: {
        ...mapMutations([
            "clearInfo",
        ]),
        ...mapActions([
            "getPhotographDetail",
        ]),
    },
    beforeRouteEnter (to, from, next) {
        // ...
        next(vm => {
            let style_id = sessionStorage.getItem("style_id") || "",
            space_id = JSON.parse(sessionStorage.getItem("space_id")) || "",
            film_type = sessionStorage.getItem("film_type") || "",
            need_dresser = sessionStorage.getItem("need_dresser") || "",
            photography_number = sessionStorage.getItem("photography_number") || 1;
            if(space_id !== ""){
                space_id = space_id[space_id.length-1]
            }
            vm.getPhotographDetail({
                theme_id: to.query.id,
                style_id,
                space_id,
                film_type,
                need_dresser,
                photography_number
            });
        });
    },
    beforeRouteLeave (to, from, next) {
        sessionStorage.removeItem("style_id");
        sessionStorage.removeItem("booking_begin");
        sessionStorage.removeItem("space_id");
        sessionStorage.removeItem("film_type");
        sessionStorage.removeItem("need_dresser");
        sessionStorage.removeItem("photography_number");
        this.clearInfo();
        next();
    },
}
</script>

<style scoped>
/* 相机租赁详情页盒子 */
.photograph_detail_box{
    background-color: #f5f5f5;
}
/* 相机租赁面包屑导航盒子 */
.breadcrumb_box{
    margin: 20px 0;
}
</style>
